
import { CsBtn, CsSimpleDialog, CsTagArea, CsUnitField } from '@consteel/csuetify'
import {
  supplyStore,
  createDefaultLength,
  getSupplyLoading,
  setSupplyDefaultLengths,
  SupplyDefaultLength,
  removeDefaultLength,
  createDefaultWaste,
  updateDefaultWaste,
  setSupplyDefaultWaste,
} from '@/modules/supply'
import Vue from 'vue'
import { DefaultTolerance, OnStockModel, onStockStore } from '@/modules/onstock'

export default Vue.extend({
  name: 'SupplySettingsDialog',
  components: {
    CsBtn,
    CsSimpleDialog,
    CsTagArea,
    CsUnitField,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultLengths: [] as string[],
      waste: DefaultTolerance,
    }
  },
  computed: {
    onstockModel(): OnStockModel | null {
      return onStockStore.onStockModel
    },
    loading(): boolean {
      return getSupplyLoading()
    },
    getDefaultLengths(): string[] {
      return supplyStore.defaultLengths.map((defaultLength) => defaultLength.length.toString())
    },
    wasteRules(): ((value: any) => true | string)[] {
      return [
        (value) => {
          if (value == null) return this.$tc('Maximum waste is required.')
          if (value < 0) return this.$tc('Maximum waste cannot be a negative number.')
          return true
        },
      ]
    },
    saveBtnDisabled(): boolean {
      return this.loading || this.waste < 0
    },
  },
  methods: {
    async handleSaveClick(): Promise<void> {
      // Default lengths
      const addedLengths: number[] = this.defaultLengths
        .filter((length: string) => !this.getDefaultLengths.includes(length))
        .map(Number)
      const removedLengths: SupplyDefaultLength[] = supplyStore.defaultLengths.filter(
        (defaultLength: SupplyDefaultLength) =>
          !this.defaultLengths.includes(defaultLength.length.toString())
      )

      if (!this.onstockModel) {
        console.log('OnStockModel does not exist!')
        return
      }

      if (addedLengths.length > 0) {
        await Promise.all(
          addedLengths.map(async (length: number) => {
            if (this.onstockModel) {
              await createDefaultLength(
                this.onstockModel.project.id.toString(),
                this.onstockModel.id.toString(),
                length
              )
            }
          })
        )
      }

      if (removedLengths.length > 0) {
        await Promise.all(
          removedLengths.map(async (defaultLength: SupplyDefaultLength) => {
            if (this.onstockModel) {
              await removeDefaultLength(
                this.onstockModel.project.id.toString(),
                this.onstockModel.id.toString(),
                defaultLength.id
              )
            }
          })
        )
      }

      // Default waste
      if (!supplyStore.defaultWaste) {
        createDefaultWaste(
          this.onstockModel.project.id.toString(),
          this.onstockModel.id.toString(),
          this.waste
        )
      } else {
        updateDefaultWaste(
          this.onstockModel.project.id.toString(),
          this.onstockModel.id.toString(),
          this.waste
        )
      }

      this.$emit('input', false)
    },
  },

  watch: {
    value(): void {
      if (this.value) {
        this.defaultLengths = this.getDefaultLengths

        this.waste = supplyStore.defaultWaste?.waste || DefaultTolerance
      }
    },
    onstockModel(): void {
      const defaultLengths = onStockStore.onStockModel?.supplyDefaultLengths
      const defaultWaste = onStockStore.onStockModel?.supplyDefaultWaste

      setSupplyDefaultWaste(defaultWaste || null)

      if (!defaultLengths) return

      setSupplyDefaultLengths(defaultLengths)
    },
  },
})
