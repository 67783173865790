
import { CsBtn } from '@consteel/csuetify'
import Vue from 'vue'
import {
  OnStockPermission,
  setOnstockModelStatus,
  getStatusItems,
  StatusItem,
  onStockStore,
  updateOnStockModel,
} from '@/modules/onstock'
import { authStore } from '@/modules/auth'
import { OnStockModelStatus } from '@/modules/graphql/graphql.types'

export default Vue.extend({
  name: 'WarehouseOptimizationStatusChanger',
  components: {
    CsBtn,
  },
  data() {
    return {
      loading: false,
      statusItems: getStatusItems(),
    }
  },
  computed: {
    statusMenuItems(): StatusItem[] {
      return Object.values(this.statusItems).filter((item) => !item.hidden)
    },
    selectedStatus(): OnStockModelStatus | undefined {
      return onStockStore.onStockModel?.status
    },
    selectedStatusItem(): StatusItem | undefined {
      if (!this.selectedStatus) return undefined

      return this.statusItems[this.selectedStatus]
    },
    selectedStatusText(): string {
      if (!this.selectedStatus) return ''

      return this.statusItems[this.selectedStatus].text
    },
    currentUserPermission(): OnStockPermission | undefined {
      const roles = authStore.currentOnStockUser?.roles?.filter(
        (role) => role !== OnStockPermission.Admin
      )[0]

      if (!roles) return

      return roles as OnStockPermission
    },
  },
  methods: {
    computeDisabled(item: StatusItem): boolean {
      return false
      /*
      if (!this.currentUserPermission || !this.selectedStatusItem) return true
      return !this.selectedStatusItem.canNavigateTo.find(
        (navigateItem) =>
          navigateItem.permission === this.currentUserPermission &&
          navigateItem.status === item.value
      )*/
    },
    async onStatusChange(statusItem: StatusItem) {
      this.loading = true
      const projectId = onStockStore.onStockModel?.projectId
      const modelId = onStockStore.onStockModel?.id

      if (!projectId || !modelId || !statusItem) {
        console.error('Some required field does not provided to status change')
        this.loading = false

        return
      }

      const updatedOnStockModel = await updateOnStockModel(
        projectId.toString(),
        modelId.toString(),
        statusItem.value
      )

      if (updatedOnStockModel) {
        setOnstockModelStatus(updatedOnStockModel.status)
      } else {
        console.error('Something went wrong during onstock model update')
      }

      this.loading = false
    },
  },
})
