
import Vue from 'vue'
import { latex_units } from '../../../services'
import {
  isSectionNameEqual,
  OnStockObjectMatch,
  onStockStore,
  OnStockWarehouseOptimization,
  OnStockWarehouseSummaryItem,
  SummaryType,
} from '@/modules/onstock'
import InfoTable from './InfoTable.vue'
import { TableNode } from './TableNode'
import { Get, StructuralMember } from '@consteel/storm'
import { modelViewerStore } from '@/store/modelViewer.store'

export default Vue.extend({
  name: 'WarehouseOptimizationSectionTable',
  components: {
    InfoTable,
  },
  props: {
    compare: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getTableItemsFromWarehouseSummaryItem(
      summaryItemBySection?: OnStockWarehouseSummaryItem,
      summaryItemByMaterial?: OnStockWarehouseSummaryItem
    ): TableNode[] {
      if (!summaryItemBySection || !summaryItemByMaterial) return []

      return [
        {
          name: this.$t('Section'),
          value: summaryItemBySection.name,
          children: [
            {
              name: this.$t('Sum of pieces'),
              value: summaryItemBySection.quantity,
              unit: 'pcs',
            },
            {
              name: this.$t('Sum of lengths'),
              value: summaryItemBySection.length,
              unit: latex_units.mm,
            },
            // {
            //   name: this.$t('Felhasznált db'),
            //   value: undefined,
            // },
            // {
            //   name: this.$t('Felhasznált hossz'),
            //   value: undefined,
            // },
            {
              name: this.$t('Member groups'),
              value: ' ',
              children: summaryItemBySection.warehouseSummaryItemBlocks.map((itemBlock) => ({
                name: itemBlock.length.toString() + ' mm',
                value: itemBlock.quantity,
                unit: 'pcs',
              })),
            },
          ],
        },
        // {
        //   name: this.$t('Anyag'),
        //   value: summaryItemByMaterial.name,
        //   children: [
        //     {
        //       name: this.$t('Össz db'),
        //       value: summaryItemByMaterial.quantity,
        //       unit: 'db',
        //     },
        //     {
        //       name: this.$t('Össz hossz'),
        //       value: summaryItemByMaterial.length,
        //       unit: latex_units.mm,
        //     },
        //     // {
        //     //   name: this.$t('Felhasznált db'),
        //     //   value: undefined,
        //     // },
        //     // {
        //     //   name: this.$t('Felhasznált hossz'),
        //     //   value: undefined,
        //     // },
        //     {
        //       name: this.$t('Elemcsoportok'),
        //       value: ' ',
        //       children: summaryItemByMaterial.warehouseSummaryItemBlocks.map((itemBlock) => ({
        //         name: itemBlock.length.toString() + ' mm',
        //         value: itemBlock.quantity,
        //         unit: 'db',
        //       })),
        //     },
        //   ],
        // },
      ]
    },
    getOnStockWarehouseSummaryItemByMaterial(
      warehouseOptimization: OnStockWarehouseOptimization
    ): OnStockWarehouseSummaryItem | undefined {
      return warehouseOptimization.warehouseSummaryItems.find(
        (warehouseSummaryItem) => warehouseSummaryItem.type === SummaryType.Material
      )
    },
    getOnStockWarehouseSummaryItemBySection(
      warehouseOptimization: OnStockWarehouseOptimization
    ): OnStockWarehouseSummaryItem | undefined {
      const selectedSectionName =
        this.selectedOnStockObjectMatch?.warehouseObject.sectionName ||
        this.structuralMember?.section?.name

      if (!selectedSectionName) return

      return warehouseOptimization.warehouseSummaryItems.find((warehouseSummaryItem) =>
        isSectionNameEqual(warehouseSummaryItem.name, selectedSectionName)
      )
    },
  },
  computed: {
    selectedOnStockObjectMatch(): OnStockObjectMatch | undefined {
      if (!onStockStore.warehouseOptimization) return

      return onStockStore.warehouseOptimization.warehouseObjectMatches.find(
        (match) => match.smadsteelId == modelViewerStore.selectedIds[0]
      )
    },
    structuralMember(): StructuralMember | null {
      if (!modelViewerStore.model.rawSmadsteelModel) {
        console.log(`Model file doesn't exist`)
        return null
      }

      return Get<StructuralMember>(
        modelViewerStore.model.rawSmadsteelModel,
        modelViewerStore.selectedIds[0],
        StructuralMember
      )
    },
    primaryItems(): TableNode[] {
      return this.getTableItemsFromWarehouseSummaryItem(
        this.primaryOnStockWarehouseSummaryItemBySection,
        this.primaryOnStockWarehouseSummaryItemByMaterial
      )
    },
    secondaryItems(): TableNode[] {
      return this.getTableItemsFromWarehouseSummaryItem(
        this.secondaryOnStockWarehouseSummaryItemBySection,
        this.secondaryOnStockWarehouseSummaryItemByMaterial
      )
    },
    primaryOnStockWarehouseSummaryItemByMaterial(): OnStockWarehouseSummaryItem | undefined {
      if (!onStockStore.warehouseOptimization) return

      return this.getOnStockWarehouseSummaryItemByMaterial(onStockStore.warehouseOptimization)
    },
    primaryOnStockWarehouseSummaryItemBySection(): OnStockWarehouseSummaryItem | undefined {
      if (!onStockStore.warehouseOptimization) return

      return this.getOnStockWarehouseSummaryItemBySection(onStockStore.warehouseOptimization)
    },
    secondaryOnStockWarehouseSummaryItemByMaterial(): OnStockWarehouseSummaryItem | undefined {
      if (!onStockStore.warehouseOptimizationSecondary) return

      return this.getOnStockWarehouseSummaryItemByMaterial(
        onStockStore.warehouseOptimizationSecondary
      )
    },
    secondaryOnStockWarehouseSummaryItemBySection(): OnStockWarehouseSummaryItem | undefined {
      if (!onStockStore.warehouseOptimizationSecondary) return

      return this.getOnStockWarehouseSummaryItemBySection(
        onStockStore.warehouseOptimizationSecondary
      )
    },
  },
})
