
import { modelViewerStore } from '@/store/modelViewer.store'
import { CsFlex } from '@consteel/csuetify'
import Vue from 'vue'
import {
  InfoTable,
  LineLoadTable,
  LoadTransferSurfaceTable,
  NodalLoadTable,
  StructuralMemberTable,
  SectionTable,
  LoadCaseTable,
  StructuralPlateTable,
  SupportPointTable,
  SurfaceLoadTable,
  WarehouseOptimizationSectionTable,
  HaunchedStructuralMemberTable,
} from './Info'
import ModelInformation from './Info/ModelInformation.vue'
import { StructuralViewModel } from '@consteel/straw'
import { isSectionNameEqual, normalizeName, onStockStore } from '@/modules/onstock'
import WarehouseOptimizationItemGroupTable from './Info/WarehouseOptimizationItemGroupTable.vue'
import { CacheContainer, GetAll, StructuralMember } from '@consteel/storm'
import { Model } from '@consteel/smadsteelloader'

interface SelectedMember {
  section: string
  material: string
}

export default Vue.extend({
  name: 'InfoTab',
  components: {
    CsFlex,
    ModelInformation,
    InfoTable,
    SectionTable,
    LoadCaseTable,
    LineLoadTable,
    WarehouseOptimizationSectionTable,
    LoadTransferSurfaceTable,
    NodalLoadTable,
    StructuralMemberTable,
    StructuralPlateTable,
    SupportPointTable,
    SurfaceLoadTable,
    WarehouseOptimizationItemGroupTable,
    HaunchedStructuralMemberTable,
  },
  data() {
    return { cache: new CacheContainer() }
  },
  computed: {
    modelFile(): Model | undefined | null {
      return modelViewerStore.model.rawSmadsteelModel
    },
    onlyOneSelected(): boolean {
      return modelViewerStore.selectedIds.length === 1
    },
    moreThanOneItemSelected(): boolean {
      return modelViewerStore.selectedIds.length > 1
    },
    showWarehouseOptimizationItemGroupTable(): boolean {
      if (!onStockStore.warehouseOptimization || !modelViewerStore.selectedIds.length) return false

      const selectedOnStockObjectMatches = modelViewerStore.selectedIds.map((id) => {
        return onStockStore.warehouseOptimization!.warehouseObjectMatches.find(
          (match) => match.smadsteelId == id
        )
      })

      const haveTheSameBarcode = selectedOnStockObjectMatches.reduce(
        ({ first, lastBarcode, sameBarcode }, objectMatch) => {
          if (!sameBarcode) return { first, lastBarcode, sameBarcode }

          const relevantBarcode = objectMatch?.warehouseObject.barcode
          if (first) {
            return {
              lastBarcode: relevantBarcode,
              first: false,
              sameBarcode: relevantBarcode != undefined,
            }
          } else {
            return {
              lastBarcode: relevantBarcode,
              first: false,
              sameBarcode: sameBarcode && relevantBarcode == lastBarcode,
            }
          }
        },
        { lastBarcode: undefined as undefined | number, sameBarcode: true, first: true }
      ).sameBarcode

      return haveTheSameBarcode
    },
    structuralMembers(): StructuralMember[] {
      if (!this.modelFile) {
        console.log(`Model file doesn't exist`)
        return []
      }

      return GetAll<StructuralMember>(this.modelFile, StructuralMember, undefined, this.cache)
    },
    showWarehouseOptimizationSectionTable(): boolean {
      if (!onStockStore.warehouseOptimization || !modelViewerStore.selectedIds.length) return false

      const selectedMembers = modelViewerStore.selectedIds.map((selectedId: string) => {
        const isMemberInWarehouseObjMatches =
          onStockStore.warehouseOptimization!.warehouseObjectMatches.find(
            (match) => match.smadsteelId == selectedId
          )

        if (isMemberInWarehouseObjMatches)
          return {
            section: isMemberInWarehouseObjMatches.warehouseObject.sectionName,
            material: isMemberInWarehouseObjMatches.warehouseObject.materialName,
          } as SelectedMember

        const selectedStructMember = this.structuralMembers.find(
          (member) => member.id === selectedId
        )

        if (!selectedStructMember) return null

        const isSectionNameInWarehouseItems =
          onStockStore.warehouseOptimization!.warehouseSummaryItems.find((summaryItem) =>
            isSectionNameEqual(summaryItem.name, selectedStructMember?.section?.name || '')
          )

        if (!isSectionNameInWarehouseItems) return null

        return {
          section: normalizeName(selectedStructMember?.section?.name || ''),
          material: normalizeName(selectedStructMember?.material?.name || ''),
        } as SelectedMember
      })

      const haveTheSameParent = selectedMembers.every(
        (selectedMember) =>
          selectedMember != null && selectedMembers[0]?.section === selectedMember?.section
      )

      return haveTheSameParent
    },
    selectedItemsHaveTheSameParent(): boolean {
      if (!modelViewerStore.model.viewModel) return false
      const firstlySelectedItemSectionId = this.getSectionOfSelectedItem(
        modelViewerStore.model.viewModel,
        modelViewerStore.selectedIds[0]
      )

      for (let i = 1; i < modelViewerStore.selectedIds.length; i++) {
        let currentItemId = this.getSectionOfSelectedItem(
          modelViewerStore.model.viewModel,
          modelViewerStore.selectedIds[i]
        )
        if (currentItemId !== firstlySelectedItemSectionId) {
          return false
        }
      }
      return true
    },
    showSectionTable(): boolean {
      return this.selectedItemsHaveTheSameParent && this.moreThanOneItemSelected
    },
    showLoadTable(): string | undefined {
      if (!modelViewerStore.activeLoadId || !modelViewerStore.model.viewModel) return undefined
      return 'LoadCaseTable'
    },
    currentSelectionTypeName(): string | undefined {
      if (!this.onlyOneSelected || !modelViewerStore.model.viewModel) return undefined

      let selectionTypeName = this.getSelectedItemTypeName(modelViewerStore.model.viewModel)

      if (modelViewerStore.secondaryModel?.viewModel && !selectionTypeName) {
        selectionTypeName = this.getSelectedItemTypeName(modelViewerStore.secondaryModel.viewModel)
      }

      return selectionTypeName
    },
    currentComponentName(): string | undefined {
      if (
        !this.currentSelectionTypeName ||
        !this.$options.components?.[`${this.currentSelectionTypeName}Table`]
      ) {
        console.warn(`${this.currentSelectionTypeName}Table not found`)
        return undefined
      }

      return `${this.currentSelectionTypeName}Table`
    },
    compare(): boolean {
      return !!(this.modelFile && modelViewerStore.secondaryModel.rawSmadsteelModel)
    },
  },
  methods: {
    getSelectedItemTypeName(viewModel: StructuralViewModel): string | undefined {
      return viewModel?.objects.find((obj) => obj.id === modelViewerStore.selectedIds[0])?.typeName
    },
    getSectionOfSelectedItem(
      viewModel: StructuralViewModel,
      selectedId: string
    ): string | undefined {
      return viewModel?.structuralMembers?.find((obj) => obj.id === selectedId)?.section.id
    },
  },
  watch: {
    modelFile(): void {
      this.cache.Clear()
    },
  },
})
