
import { latex_units, roundToTwoDigits } from '@/services/unit.service'
import { exportMaterialList } from '@/modules/model/model.service'
import { modelViewerStore } from '@/store/modelViewer.store'
import { modelStore } from '@/modules/model/model.store'
import { GetAll, StructuralMember, Get, CacheContainer } from '@consteel/storm'
import Vue from 'vue'
import { CsFlex } from '@consteel/csuetify'
import ModelInformationCard from './ModelInformationCard.vue'
import ChevronDownSvg from '@/assets/common/ChevronDown.svg.vue'
import { Model } from '@consteel/smadsteelloader'

export default Vue.extend({
  name: 'GeneralInfoTable',
  components: {
    CsFlex,
    ChevronDownSvg,
    ModelInformationCard,
  },
  data() {
    return {
      cache: new CacheContainer(),
      open: true,
    }
  },
  methods: {
    getNumberOfBars(structuralMembers: StructuralMember[]): number {
      return structuralMembers.length
    },
    getTotalLength(structuralMembers: StructuralMember[]): string {
      const length = structuralMembers.reduce<number>(
        (sum: number, item: StructuralMember) => sum + (item.geometry?.length || 0),
        0
      )

      return roundToTwoDigits(length)
    },
    getTotalSurface(structuralMembers: StructuralMember[]): string {
      const surface = structuralMembers.reduce<number>((sum: number, item: StructuralMember) => {
        const perimeter = item.section?.properties?.perimeter || 0

        return sum + perimeter * (item.geometry?.length || 0)
      }, 0)

      return roundToTwoDigits(surface)
    },
    getTotalWeight(structuralMembers: StructuralMember[]): string {
      const weight =
        structuralMembers.reduce<number>((sum, item) => {
          const area = item.section?.properties?.area
          const material = structuralMembers.find((member) => member.id === item.id)?.material

          if (!material) {
            console.error('No material found')
            return sum
          }

          if (material.typeName === 'CONCRETE') return sum

          if (area && material.density) {
            return sum + area * (item.geometry?.length ?? 0) * material.density
          }
          return sum
        }, 0) / 1000

      return roundToTwoDigits(weight)
    },
    getStructuralMembersFromModel(model: Model): StructuralMember[] {
      let structuralMembers: StructuralMember[] = []

      if (modelViewerStore.selectedIds.length) {
        modelViewerStore.selectedIds.forEach((id) => {
          const item = Get<StructuralMember>(model, id, StructuralMember)

          if (item) structuralMembers.push(item)
        })
      } else {
        structuralMembers = GetAll<StructuralMember>(model, StructuralMember, undefined, this.cache)
      }

      return structuralMembers
    },
    handleExportMaterialList() {
      exportMaterialList(modelStore.model)
    },
  },
  computed: {
    compare(): boolean {
      return !!(
        modelViewerStore.model.rawSmadsteelModel &&
        modelViewerStore.secondaryModel.rawSmadsteelModel
      )
    },
    numberOfBarsChanged(): boolean {
      return this.numberOfBars !== this.numberOfBarsSecondary
    },
    totalWeightChanged(): boolean {
      return this.totalWeight !== this.totalWeightSecondary
    },
    totalSurfaceChanged(): boolean {
      return this.totalSurface !== this.totalSurfaceSecondary
    },
    totalLengthChanged(): boolean {
      return this.totalLength !== this.totalLengthSecondary
    },
    primaryModelNumber(): string | null | undefined {
      return modelViewerStore.model.modelNumber
    },
    secondaryModelNumber(): string | null | undefined {
      return modelViewerStore.secondaryModel.modelNumber
    },
    selectedIds(): string[] {
      return modelViewerStore.selectedIds
    },
    model(): Model | null | undefined {
      return modelViewerStore.model.rawSmadsteelModel
    },
    currentView() {
      return modelViewerStore.currentView
    },
    structuralMembers(): StructuralMember[] {
      if (!modelViewerStore.model.rawSmadsteelModel) return []

      return this.getStructuralMembersFromModel(modelViewerStore.model.rawSmadsteelModel)
    },
    structuralMembersSecondary(): StructuralMember[] {
      if (!modelViewerStore.secondaryModel.rawSmadsteelModel) return []

      return this.getStructuralMembersFromModel(modelViewerStore.secondaryModel.rawSmadsteelModel)
    },
    numberOfBars(): number {
      return this.getNumberOfBars(this.structuralMembers)
    },
    numberOfBarsSecondary(): number {
      return this.getNumberOfBars(this.structuralMembersSecondary)
    },
    totalLength(): string {
      return this.getTotalLength(this.structuralMembers)
    },
    totalLengthSecondary(): string {
      return this.getTotalLength(this.structuralMembersSecondary)
    },
    lengthUnit(): string {
      return latex_units.m
    },
    totalSurface(): string {
      return this.getTotalSurface(this.structuralMembers)
    },
    totalSurfaceSecondary(): string {
      return this.getTotalSurface(this.structuralMembersSecondary)
    },
    surfaceUnit(): string {
      return latex_units.m2
    },
    totalWeight(): string {
      return this.getTotalWeight(this.structuralMembers)
    },
    totalWeightSecondary(): string {
      return this.getTotalWeight(this.structuralMembersSecondary)
    },
    weightUnit(): string {
      return latex_units.t
    },
  },
  watch: {
    model(): void {
      this.cache.Clear()
    },
  },
})
