
import { CsBtn, CsSelect, CsSimpleDialog, CsWarningDialog } from '@consteel/csuetify'
import { GetAll } from '@consteel/storm'
import { Portion } from '@consteel/storm'
import { guid } from '@consteel/storm/src/Models/ModelBase'

type DesignerSetting = {
  id?: number
  portion?: { text: string; value: guid }
  condition?: { text: string; value: EngineerRuleCondition }
  delete?: boolean
}

import Vue from 'vue'
import { DataTableHeader } from 'vuetify'
import {
  createOnStockEngineerRule,
  deleteOnStockEngineerRule,
  getEngineerRuleConditionText,
  OnStockModel,
  onStockStore,
  updateOnStockEngineerRule,
} from '../../modules/onstock'
import { EngineerRuleCondition } from '../../modules/onstock/types/OnStockEngineerRule.model'
import { modelViewerStore } from '../../store/modelViewer.store'
export default Vue.extend({
  name: 'EngineerRulesDialog',
  components: {
    CsBtn,
    CsSimpleDialog,
    CsSelect,
    CsWarningDialog,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: '',
      loading: false,
      items: [] as DesignerSetting[],
      deleteEngineerRuleIndex: null as number | null,
      showDeleteEngineerRuleDialog: false,
    }
  },
  mounted() {
    this.items = this.getInitEngineerSettings()
  },
  methods: {
    customFilter(_, search: string | null, item): boolean {
      if (!search) return true

      return item.portion.text.includes(search) || item.condition.text.includes(search)
    },
    async handleClickSave(): Promise<void> {
      this.loading = true
      const editable = [] as DesignerSetting[]
      const creatable = [] as DesignerSetting[]
      const initEngineerSettings = this.getInitEngineerSettings()

      //select rules to delete
      const deletable = initEngineerSettings.filter((setting) => {
        return !this.items.find((item) => item.id == setting.id)
      })

      this.items.forEach((item) => {
        //select rules to create
        if (!item.id && item.portion && item.condition) {
          creatable.push(item)
          return
        }

        const relevantInitItem = initEngineerSettings.find((initItem) => initItem.id === item.id)

        if (!relevantInitItem) {
          console.warn('Relevant settings not found') //this should never happen
          return
        }

        //select rules to edit
        if (
          item.condition?.value !== relevantInitItem.condition?.value ||
          item.portion?.value !== relevantInitItem?.portion?.value
        ) {
          editable.push(item)
        }
      })

      if (!this.onstockModel?.project) {
        console.warn('OnstockModel is not set')
        return
      }

      await Promise.all(
        editable.map(async (item) => {
          if (item.id && this.onstockModel && item.portion && item.condition) {
            await updateOnStockEngineerRule(
              this.onstockModel.project.id,
              this.onstockModel.id,
              item.id,
              item.portion.value,
              item.portion.text,
              item.condition?.value
            )
          }
        })
      )

      await Promise.all(
        creatable.map(async (item) => {
          if (this.onstockModel && item.portion && item.condition) {
            await createOnStockEngineerRule(
              this.onstockModel.project.id,
              this.onstockModel.id,
              item.portion.value,
              item.portion.text,
              item.condition?.value
            )
          }
        })
      )

      await Promise.all(
        deletable.map(async (item) => {
          if (this.onstockModel && item.id)
            await deleteOnStockEngineerRule(
              this.onstockModel.project.id,
              this.onstockModel.id,
              item.id
            )
        })
      )

      this.loading = false
      this.$emit('input', false)
    },
    handleClickDeleteRuleActionBtn(index: number) {
      this.showDeleteEngineerRuleDialog = true
      this.deleteEngineerRuleIndex = index
    },
    handleClickRemoveItem(): void {
      this.showDeleteEngineerRuleDialog = false

      if (this.deleteEngineerRuleIndex == null) return

      this.items.splice(this.deleteEngineerRuleIndex, 1)

      this.deleteEngineerRuleIndex = null
    },
    handleClickAddSettings(): void {
      const dummyElementIsAdded = this.items.find((item) => item.portion == null)
      if (!dummyElementIsAdded)
        this.items = [...this.items, { portion: undefined, condition: undefined }]
    },
    computeDisabledPortions(currentSetting: DesignerSetting): {
      text: string
      value: guid
      disabled: boolean
    }[] {
      return this.portions.map((portion) => {
        return {
          ...portion,
          disabled:
            !!this.items.find((item) => item.portion?.value == portion.value) &&
            currentSetting.portion?.value != portion.value,
        }
      })
    },
    getInitEngineerSettings(): DesignerSetting[] {
      if (!onStockStore.onStockModel?.engineerRules) return []

      return onStockStore.onStockModel.engineerRules.map((rule) => {
        return {
          portion: {
            text: rule.portionName,
            value: rule.portionSmadsteelId,
          },
          condition: {
            text: getEngineerRuleConditionText(rule.condition),
            value: rule.condition,
          },
          delete: false,
          id: rule.id,
        }
      })
    },
  },
  computed: {
    onstockModel(): OnStockModel | null {
      return onStockStore.onStockModel
    },
    disabledSave(): boolean {
      return this.loading
    },
    portions(): Array<{ text: string; value: guid }> {
      if (!modelViewerStore.model.rawSmadsteelModel) return []

      const portions = GetAll<Portion>(modelViewerStore.model.rawSmadsteelModel, Portion).map(
        (portion) => ({
          text: portion.name || '',
          value: portion.id || '',
        })
      )

      return portions
    },
    conditions(): Array<{ text: string; value: EngineerRuleCondition }> {
      return [
        {
          text: this.$t('Section remains the same, but material can change').toString(),
          value: EngineerRuleCondition.OnlyMaterialChangeAllowed,
        },
        {
          text: this.$t('Neither the section nor the material can change').toString(),
          value: EngineerRuleCondition.NoChangeAllowed,
        },
        {
          text: this.$t('Both section and material can change').toString(),
          value: EngineerRuleCondition.MaterialAndSectionChangeAllowed,
        },
      ]
    },
    headers(): DataTableHeader[] {
      return [
        {
          text: this.$t('Portion').toString(),
          align: 'start',
          sortable: false,
          value: 'portion',
          width: '200px',
        },
        {
          text: this.$t('Condition').toString(),
          value: 'condition',
          sortable: false,
          width: '280px',
        },
        { text: '', value: 'delete', sortable: false, width: '70px' },
      ]
    },
  },
  watch: {
    onstockModel(): void {
      this.items = this.getInitEngineerSettings()
    },
    value(): void {
      this.items = this.getInitEngineerSettings()
    },
  },
})
