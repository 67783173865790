
import InfoTable from './InfoTable.vue'
import Vue from 'vue'
import { onStockStore } from '@/modules/onstock'
import { modelViewerStore } from '../../../store/modelViewer.store'
import { TableNode } from './TableNode'
import { Get, StructuralMember } from '@consteel/storm'
import { Model } from '@consteel/smadsteelloader'
import { WarehouseObjectMatch } from '@/modules/graphql/graphql.types'

export default Vue.extend({
  name: 'WarehouseOptimizationItemGroupTable',
  components: {
    InfoTable,
  },
  props: {
    compare: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getSelectedObjectMatchGroupLeftLength(
      selectedOnStockObjectMatchGroup: WarehouseObjectMatch[],
      selectedOnStockObjectMatch?: WarehouseObjectMatch
    ): number {
      if (!selectedOnStockObjectMatch?.warehouseObject.length) return 0

      const usedLength = selectedOnStockObjectMatchGroup.reduce((sum, currentMatch) => {
        if (!this.modelFile) return 0

        const relevantSmadsteelObject = Get<StructuralMember>(
          this.modelFile,
          currentMatch.smadsteelId,
          StructuralMember
        )
        return sum + (relevantSmadsteelObject?.geometry?.length || 0) * 1000 //because it is in meter
      }, 0)

      return selectedOnStockObjectMatch.warehouseObject.length - usedLength
    },
    getSelectedObjectMatchGroupQuantity(
      selectedOnStockObjectMatchGroup: WarehouseObjectMatch[]
    ): number {
      return selectedOnStockObjectMatchGroup.length
    },
    getTableItemsFromWarehouseSummaryItem(
      selectedOnStockObjectMatchGroup: WarehouseObjectMatch[],
      selectedOnStockObjectMatch?: WarehouseObjectMatch
    ): TableNode[] {
      if (!selectedOnStockObjectMatch) return []

      return [
        {
          name: this.$t('Azonosító'),
          value: selectedOnStockObjectMatch.warehouseObject.barcode,
        },
        {
          name: this.$t('Szelvény'),
          value: selectedOnStockObjectMatch.warehouseObject.sectionName,
        },
        {
          name: this.$t('Anyag'),
          value: selectedOnStockObjectMatch.warehouseObject.materialName,
        },
        {
          name: this.$t('Length'),
          value: selectedOnStockObjectMatch.warehouseObject.length,
        },
        {
          name: this.$t('Modell elem'),
          value: this.getSelectedObjectMatchGroupQuantity(selectedOnStockObjectMatchGroup),
          unit: 'db',
        },
        {
          name: this.$t('Maradék hossz'),
          value: this.getSelectedObjectMatchGroupLeftLength(
            selectedOnStockObjectMatchGroup,
            selectedOnStockObjectMatch
          ).toFixed(),
          unit: 'mm',
        },
      ]
    },
  },
  computed: {
    primaryItems(): TableNode[] {
      return this.getTableItemsFromWarehouseSummaryItem(
        this.selectedOnStockObjectMatchGroup,
        this.selectedOnStockObjectMatch
      )
    },
    secondaryItems(): TableNode[] {
      return this.getTableItemsFromWarehouseSummaryItem(
        this.selectedOnStockObjectMatchGroupSecondary,
        this.selectedOnStockObjectMatchSecondary
      )
    },
    selectedOnStockObjectMatch(): WarehouseObjectMatch | undefined {
      if (!onStockStore.warehouseOptimization) return

      const selectedSmadsteelObjectId = modelViewerStore.selectedIds[0]

      return onStockStore.warehouseOptimization.warehouseObjectMatches.find(
        (match) => match.smadsteelId == selectedSmadsteelObjectId
      )
    },
    selectedOnStockObjectMatchSecondary(): WarehouseObjectMatch | undefined {
      if (!onStockStore.warehouseOptimizationSecondary) return

      const selectedSmadsteelObjectId = modelViewerStore.selectedIds[0]

      return onStockStore.warehouseOptimizationSecondary.warehouseObjectMatches.find(
        (match) => match.smadsteelId == selectedSmadsteelObjectId
      )
    },
    selectedOnStockObjectMatchGroup(): WarehouseObjectMatch[] {
      if (!onStockStore.warehouseOptimization) return []

      const barcode = this.selectedOnStockObjectMatch?.warehouseObject.barcode

      if (!barcode) return []

      return onStockStore.warehouseOptimization.warehouseObjectMatches.filter((objectMatch) => {
        return objectMatch.warehouseObject.barcode == barcode
      })
    },
    selectedOnStockObjectMatchGroupSecondary(): WarehouseObjectMatch[] {
      if (!onStockStore.warehouseOptimizationSecondary) return []

      const barcode = this.selectedOnStockObjectMatchSecondary?.warehouseObject.barcode

      if (!barcode) return []

      return onStockStore.warehouseOptimizationSecondary.warehouseObjectMatches.filter(
        (objectMatch) => {
          return objectMatch.warehouseObject.barcode == barcode
        }
      )
    },
    modelFile(): Model | undefined | null {
      return modelViewerStore.model.rawSmadsteelModel
    },
  },
})
