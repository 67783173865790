var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "cs-simple-dialog",
        {
          attrs: {
            value: _vm.value,
            title: _vm.$t("Default supply optimization settings"),
            width: 600,
          },
          on: {
            input: function ($event) {
              return _vm.$emit("input", false)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "actions",
              fn: function () {
                return [
                  _c(
                    "cs-btn",
                    {
                      staticClass: "mr-4",
                      attrs: { outlined: "", disabled: _vm.loading },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("input", false)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]
                  ),
                  _c(
                    "cs-btn",
                    {
                      attrs: {
                        loading: _vm.loading,
                        disabled: _vm.saveBtnDisabled,
                      },
                      on: { click: _vm.handleSaveClick },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Save")) + " ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            [
              _vm.loading
                ? _c("v-progress-linear", { attrs: { indeterminate: "" } })
                : _vm._e(),
              _c("div", { staticClass: "supply-settings-dialog-subtitle" }, [
                _vm._v(" " + _vm._s(_vm.$t("Default purchase lengths")) + " "),
              ]),
              _c("div", { staticClass: "mt-1" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        `You can define default purchase lengths that are assigned to every section type by default. You can override these or add additional lenghts for every section type at the start of the optimization.`
                      )
                    ) +
                    " "
                ),
              ]),
              _c(
                "div",
                { staticClass: "mt-6" },
                [
                  _c("cs-tag-area", {
                    staticClass: "supply-settings-dialog-tag-area",
                    attrs: {
                      placeholder: _vm.$t(
                        "Define purchase lengths, divide them with TAB"
                      ),
                      label: _vm.$t("Purchase lengths [mm]"),
                      persistentPlaceholder: "",
                    },
                    model: {
                      value: _vm.defaultLengths,
                      callback: function ($$v) {
                        _vm.defaultLengths = $$v
                      },
                      expression: "defaultLengths",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "supply-settings-dialog-subtitle mt-8" },
                [_vm._v(" " + _vm._s(_vm.$t("Default maximum waste")) + " ")]
              ),
              _c("div", { staticClass: "mt-1" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "The default maximum waste can be defined here. You can overrie this for every section type at the start of the optimization"
                      )
                    ) +
                    " "
                ),
              ]),
              _c("cs-unit-field", {
                staticClass: "mt-4",
                attrs: {
                  title: _vm.$t("Maximum waste"),
                  rules: _vm.wasteRules,
                  unit: "mm",
                  maxWidth: "110px",
                  precision: 0,
                },
                model: {
                  value: _vm.waste,
                  callback: function ($$v) {
                    _vm.waste = $$v
                  },
                  expression: "waste",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }