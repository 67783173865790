var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "cs-simple-dialog",
        {
          attrs: {
            infoText: _vm.$t(
              "In the engineer settings, it is possible to set conditions for the entire structure or for specific model components, which determine the accuracy of the optimization."
            ),
            value: _vm.value,
            title: _vm.$t("Engineer settings"),
            width: 600,
          },
          on: {
            input: function ($event) {
              return _vm.$emit("input", false)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "actions",
              fn: function () {
                return [
                  _c(
                    "cs-btn",
                    {
                      staticClass: "mr-4",
                      attrs: { outlined: "", disabled: _vm.loading },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("input", false)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]
                  ),
                  _c(
                    "cs-btn",
                    {
                      attrs: {
                        loading: _vm.loading,
                        disabled: _vm.loading || _vm.disabledSave,
                      },
                      on: { click: _vm.handleClickSave },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Save")) + " ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            [
              _vm.loading
                ? _c("v-progress-linear", { attrs: { indeterminate: "" } })
                : _vm._e(),
              _c("div", { staticClass: "d-flex justify-end" }, [
                _c(
                  "div",
                  { staticClass: "search-field" },
                  [
                    _c("v-text-field", {
                      staticClass: "pt-0 mt-0",
                      attrs: {
                        dense: "",
                        "append-icon": "mdi-magnify",
                        label: "",
                        "single-line": "",
                        "hide-details": "",
                      },
                      model: {
                        value: _vm.search,
                        callback: function ($$v) {
                          _vm.search = $$v
                        },
                        expression: "search",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("v-data-table", {
                attrs: {
                  "filter-mode": "union",
                  height: 250,
                  headers: _vm.headers,
                  items: _vm.items,
                  search: _vm.search,
                  "custom-filter": _vm.customFilter,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: `item.portion`,
                      fn: function ({ item }) {
                        return [
                          _c("cs-select", {
                            staticClass: "portion",
                            attrs: {
                              dense: "",
                              "full-width": "",
                              items: _vm.computeDisabledPortions(item),
                              "return-object": "",
                              disabled: _vm.loading,
                            },
                            model: {
                              value: item.portion,
                              callback: function ($$v) {
                                _vm.$set(item, "portion", $$v)
                              },
                              expression: "item.portion",
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: `item.condition`,
                      fn: function ({ item }) {
                        return [
                          _c("cs-select", {
                            staticClass: "condition-select",
                            attrs: {
                              "full-width": "",
                              dense: "",
                              items: _vm.conditions,
                              "return-object": "",
                              disabled: _vm.loading,
                            },
                            model: {
                              value: item.condition,
                              callback: function ($$v) {
                                _vm.$set(item, "condition", $$v)
                              },
                              expression: "item.condition",
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: `item.delete`,
                      fn: function ({ index }) {
                        return [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", icon: "" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.handleClickDeleteRuleActionBtn(
                                    index
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "v-icon",
                                { attrs: { color: "grey darken-1" } },
                                [_vm._v(" mdi-delete ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: `footer`,
                      fn: function () {
                        return [
                          _c(
                            "cs-btn",
                            {
                              attrs: {
                                disabled: _vm.loading,
                                outlined: "",
                                width: "100%",
                              },
                              on: { click: _vm.handleClickAddSettings },
                            },
                            [_c("v-icon", [_vm._v("mdi-plus")])],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          ),
        ]
      ),
      _c("cs-warning-dialog", {
        attrs: {
          twoButton: "",
          width: "500",
          submitText: _vm.$t("Delete"),
          description: _vm.$t(
            "The deletion is irreversible and the setting will no longer be available!"
          ),
          title: _vm.$t(
            "Are you sure you want to delete the engineer setting?"
          ),
        },
        on: { submit: _vm.handleClickRemoveItem },
        model: {
          value: _vm.showDeleteEngineerRuleDialog,
          callback: function ($$v) {
            _vm.showDeleteEngineerRuleDialog = $$v
          },
          expression: "showDeleteEngineerRuleDialog",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }