
import { getStructuralMember } from '@/modules/storm/storm.service'
import { latex_units, roundToTwoDigits } from '@/services/unit.service'
import { modelViewerStore } from '@/store/modelViewer.store'
import { StructuralMember } from '@consteel/storm'
import Vue from 'vue'
import InfoTable from './InfoTable.vue'
import { TableNode } from './TableNode'
export default Vue.extend({
  name: 'SectionTable',
  components: {
    InfoTable,
  },
  props: {
    compare: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    primaryMember(): StructuralMember | null {
      return getStructuralMember(
        modelViewerStore.model.rawSmadsteelModel,
        modelViewerStore.selectedIds[0]
      )
    },
    secondaryMember(): StructuralMember | null {
      if (!this.compare) return null

      return getStructuralMember(
        modelViewerStore.secondaryModel.rawSmadsteelModel,
        modelViewerStore.selectedIds[0],
        true
      )
    },
    primaryItems(): TableNode[] {
      return this.getTableItemsFromModelItem(this.primaryMember)
    },
    secondaryItems(): TableNode[] {
      return this.getTableItemsFromModelItem(this.secondaryMember)
    },
  },
  methods: {
    getTableItemsFromModelItem(item: StructuralMember | null): TableNode[] {
      if (!item) return []
      return [
        {
          name: this.$t('Name'),
          value: item.section?.name,
        },
        {
          name: this.$t('Type'),
          value: item.section?.type,
        },
        {
          name: this.$t('Subtype'),
          value: item.section?.subType,
        },
        {
          name: this.$t('Group'),
          value: item.section?.groupName,
        },
        {
          name: this.$t('Description'),
          value: item.section?.description,
        },
        {
          name: this.$t('Source'),
          value: item.section?.origin,
        },
        {
          name: this.$t('Area'),
          value: roundToTwoDigits((item.section?.properties?.area ?? 0) * 1000000),
          unit: latex_units.mm2,
        },
        {
          name: this.$t('Perimeter'),
          value: roundToTwoDigits((item.section?.properties?.perimeter ?? 0) * 1000),
          unit: latex_units.mm,
        },
      ]
    },
  },
})
