
import Vue from 'vue'
import FloatingTabItem from '@/components/FloatingTabs/FloatingTabItem.vue'
import InfoSvg from '@/assets/common/Info.svg.vue'
import OnStockLogoSvg from '@/assets/OnStockLogo.svg.vue'
import BoxSvg from '@/assets/viewer/Box.svg.vue'
import ProfilePlusSvg from '@/assets/viewer/ProfilePlus.svg.vue'
import ChangeThemeButton from '@/components/ChangeThemeButton.vue'
import ShareDialog from '@/components/Dialogs/ShareDialog.vue'
import FloatingTabs from '@/components/FloatingTabs/FloatingTabs.vue'
import TabButton from '@/components/FloatingTabs/TabButton.vue'
import ToolbarMenu from '@/components/ToolbarMenu.vue'
import InfoTab from '@/components/Viewer/InfoTab.vue'
import {
  CsBreadcrumbs,
  CsBtn,
  CsFlex,
  CsRadio,
  CsRadioGroup,
  CsSelect,
  CsSimpleDialog,
} from '@consteel/csuetify'
import CompareSvg from '@/assets/viewer/Compare.svg.vue'
import WarehouseOptimizationStatusChanger from './WarehouseOptimizationStatusChanger.vue'
import { modelViewerStore } from '../../store/modelViewer.store'
import SettingsSvg from '../../assets/common/Settings.svg.vue'
import EngineerRulesDialog from '../Dialogs/EngineerRulesDialog.vue'
import ManagerRulesSummaryDialog from '../Dialogs/ManagerRulesDialog/ManagerRulesSummaryDialog.vue'
import { getOnstockLoading, onStockStore } from '../../modules/onstock'
import ManageCollaboratorsDialog from '../Dialogs/ManageCollaboratorsDialog.vue'
import SupplySettingsDialog from '../Dialogs/SupplySettingsDialog.vue'
import { OnStockProject } from '@/modules/graphql/graphql.types'

export default Vue.extend({
  name: 'ModelViewerHeader',
  components: {
    ManageCollaboratorsDialog,
    EngineerRulesDialog,
    SupplySettingsDialog,
    WarehouseOptimizationStatusChanger,
    ChangeThemeButton,
    CsBtn,
    CsFlex,
    CsBreadcrumbs,
    FloatingTabs,
    InfoSvg,
    InfoTab,
    ProfilePlusSvg,
    ShareDialog,
    OnStockLogoSvg,
    TabButton,
    ToolbarMenu,
    FloatingTabItem,
    SettingsSvg,
    CsSimpleDialog,
    CsRadio,
    CsRadioGroup,
    CsSelect,
    ManagerRulesSummaryDialog,
  },
  props: {
    contentWidth: {
      required: false,
      type: [Number, String],
      default: 350,
    },
    model: {
      type: Object,
      default: null,
    },
    breadcrumbsItems: {
      type: Array,
    },
    currentModelShares: {
      type: Array,
    },
    currentModelIsPublic: {
      type: Boolean,
    },
  },
  data() {
    return {
      setting: null,
      showShareModal: false,
      showEngineerRulesDialog: false,
      showManagerRulesSummaryDialog: false,
      showSupplySettingsDialog: false,
      modelParentFolderName: undefined as string | undefined,
      modelId: undefined as string | undefined,
    }
  },
  methods: {
    navigateToModel(): void {
      this.$emit('navigateToModel')
    },
    navigateToCompare(): void {
      this.$emit('navigateToCompare')
    },
  },
  computed: {
    project(): OnStockProject | null {
      return onStockStore.project
    },
    disableSettings(): boolean {
      return modelViewerStore.currentViewLoading || getOnstockLoading()
    },
    isActiveSettings(): boolean {
      return (
        this.showEngineerRulesDialog || this.showManagerRulesSummaryDialog || this.showShareModal
      )
    },
    settingsItems(): { click: () => void; text: string; value: number }[] {
      return [
        {
          text: this.$t('Engineer settings').toString(),
          click: () => (this.showEngineerRulesDialog = !this.showEngineerRulesDialog),
          value: 1,
        },
        {
          text: this.$t('Share settings').toString(),
          click: () => (this.showShareModal = !this.showShareModal),
          value: 2,
        },
        {
          text: this.$t('Warehouse optimization rules').toString(),
          click: () => (this.showManagerRulesSummaryDialog = !this.showManagerRulesSummaryDialog),
          value: 3,
        },
        {
          text: this.$t('Supply optimization rules').toString(),
          click: () => (this.showSupplySettingsDialog = !this.showSupplySettingsDialog),
          value: 4,
        },
      ]
    },
    activeMainTab(): number {
      return modelViewerStore.activeMainTab
    },
    activeSideTab(): number {
      return modelViewerStore.activeSideTab
    },
    tabItems(): Array<Record<string, unknown>> {
      return [
        {
          icon: BoxSvg,
          text: 'Model',
          onClick: this.navigateToModel,
        },
        /* temporary
        {
          icon: CompareSvg,
          text: 'Compare',
          onClick: this.navigateToCompare,
        },*/
      ]
    },
  },
  watch: {
    showEngineerRulesDialog(value: boolean): void {
      if (!value) this.setting = null
    },
    showShareModal(value: boolean): void {
      if (!value) this.setting = null
    },
    showManagerRulesSummaryDialog(value: boolean): void {
      if (!value) this.setting = null
    },
    showSupplySettingsDialog(value: boolean): void {
      if (!value) this.setting = null
    },
  },
})
