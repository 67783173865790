var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("info-table", {
    attrs: {
      header: _vm.$t("Warehouse stock information"),
      items: _vm.primaryItems,
      compareItems: _vm.secondaryItems,
      compare: _vm.compare,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }