var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.CsFlex,
    {
      class: "info-tab",
      attrs: {
        "fill-height": "",
        "fill-width": "",
        vertical: "",
        justify: "flex-start",
      },
    },
    [
      _c(_setup.ModelInformation),
      _setup.showWarehouseOptimizationSectionTable
        ? _c(_setup.WarehouseOptimizationSectionTable, {
            attrs: { compare: _setup.compare },
          })
        : _vm._e(),
      _setup.showWarehouseOptimizationItemGroupTable
        ? _c(_setup.WarehouseOptimizationItemGroupTable, {
            attrs: { compare: _setup.compare },
          })
        : _vm._e(),
      _setup.showLoadTable
        ? _c(
            _setup.showLoadTable,
            _vm._b(
              { tag: "load-case-table" },
              "load-case-table",
              { compare: _setup.compare },
              false
            )
          )
        : _vm._e(),
      _setup.currentComponentName
        ? _c(
            _setup.currentComponentName,
            _vm._b(
              { tag: "component" },
              "component",
              { compare: _setup.compare },
              false
            )
          )
        : _vm._e(),
      _setup.showSectionTable
        ? _c(_setup.SectionTable, { attrs: { compare: _setup.compare } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }