
import { CsFlex, CsLatexField } from '@consteel/csuetify'
import Vue from 'vue'

export default Vue.extend({
  name: 'SteelBarMembers',
  components: {
    CsFlex,
    CsLatexField,
  },
  props: {
    compare: {
      type: Boolean,
      default: false,
    },
    changed: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
    value: {
      type: [Number, String],
    },
    unit: {
      type: String,
    },
    valueSecondary: {
      type: [String, Number],
    },
    modelNumber: {
      type: String,
    },
    modelNumberSecondary: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
})
